import animationData from "@public/lotties/insurance-protection.json";
import Image from "next/image";
import Link from "next/link";
import Lottie from "react-lottie-player";
import styles from "./loginSideBar.module.scss";
import iconCloudWhite from "@public/icon-cloud-white.svg";

export default function LoginSideBar() {
  return (
    <section className={styles.sideBar}>
      <header>
        <Link href="/">
          <a>
            <Image
              src={iconCloudWhite}
              alt="Segurize Icon"
              height={45}
              width={100}
            />
          </a>
        </Link>
      </header>

      <h5>É simples. É fácil. É Segurize.</h5>

      <div className={styles.illustration}>
        <Lottie
          animationData={animationData}
          loop
          play
          style={{ width: "100%", height: "65vh" }}
        />
      </div>

      <span>
        Ilustração por:{" "}
        <a
          href="https://lottiefiles.com/drawer"
          target="_blank"
          rel="noreferrer"
        >
          Drawer Design
        </a>
      </span>
    </section>
  );
}
