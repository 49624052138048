import Link from "next/link";
import { ReactNode } from "react";
import styles from "./stylizedLink.module.scss";

interface StylizedLinkProps {
  alignDirection: "center" | "left" | "right";
  to: string;
  onClick?: () => void;
  children: ReactNode;
}

const StylizedLink: React.FC<StylizedLinkProps> = ({
  to,
  alignDirection,
  onClick,
  children,
}) => {
  return (
    <div
      className={`${styles.stylizedLink} ${styles[alignDirection]}`}
      onClick={onClick}
    >
      <Link href={to}>
        <a>{children}</a>
      </Link>
    </div>
  );
};

export default StylizedLink;
